import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"leistungen"} />
		<Helmet>
			<title>
				Website gesucht?
			</title>
			<meta name={"description"} content={"Websites by CLEM1"} />
			<meta property={"og:title"} content={"Websites für jedermann by CLEM1"} />
			<meta property={"og:description"} content={"Keine Website, kein Problem ist mein Motto. Ich biete eine moderne Website für jedermann zu attraktiven Preisen. Einfache Websites, oder aufwendiges Design? Kein Problem!"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header>
			<Override slot="link2" />
		</Components.Header>
		<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#161618">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
				flex-direction="column"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
					width="max-content"
					font="normal 700 60px/1.2 &quot;inter&quot;,sans-serif"
					align-self="auto"
					flex="0 0 auto"
				>
					Kosten & Leistungen{" "}
					<br />
					im Überblick
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				width="45%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					lg-max-height="400px"
					width="100% border-box"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					hover-opacity="1"
					hover-transition="all --transitionDuration-normal ease 20ms"
					hover-transform="translate(5px, 5px) skew(2deg, 0deg) scale3d(1.1, 1.1, 1.1)"
					transition="all --transitionDuration-normal ease 20ms"
					src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/d32eaae1-9c1b-4245-3472-a77be2efef00/public"
					border-radius="10px"
				/>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" background="--color-richtigesgrau">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				align-items="center"
				margin="0px 0px 30px 0px"
				padding="0px 50px 0px 50px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--proweiss"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Leistungen die Sie benötigen
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--proweiss"
					md-text-align="center"
					text-align="center"
					width="max-content"
				>
					Wir bieten Ihnen verschiedene Leistungen. Von Design über Hosting sowie Support.{" "}
					<br />
					Mit uns erhalten Sie einen Partner der Ihnen zur Seite steht und auf Ihre Bedürfnisse eingeht.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					background="--color-pr0Orange"
					color="--proweiss"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 18px 0px" color="--proweiss" font="--headline3" lg-text-align="left">
						Design
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ihr Wunschdesign kann ganz individuell erfolgen wie Sie es wünschen.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					background="--color-pr0Orange"
					color="--proweiss"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 18px 0px" color="--proweiss" font="--headline3" lg-text-align="left">
						Hosting
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ihre Daten werden redundant in der Cloud gespeichert ohne Sorge um Datenverlust.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
					background="--color-pr0Orange"
					color="--proweiss"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 18px 0px" color="--proweiss" font="--headline3" lg-text-align="left">
						Support
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ihr Projekt wird dauerhaft betreut. Änderungen können jederzeit erfolgen.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-richtigesgrau" padding="100px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 30px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				lg-padding="0px 0px 0px 0px"
				padding="0px 50px 0px 50px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--proweiss"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="max-content"
				>
					Kosten die überschaubar sind
				</Text>
				<Text margin="0px 0px 0px 0px" color="--proweiss" text-align="center" font="--lead">
					Hier finden Sie eine Übersicht der Kosten die Sie erwarten werden. Je nach Bedarf kann natürlich auch ein individuelles Angebot erfolgen.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-pr0Orange"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
					color="--proweiss"
					height="max-content"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						einmalig
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline1">
						469€
					</Text>
					<Text
						margin="0px 0px 32px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						background="--color-pr0Orange"
					>
						einmalig
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						flex="1 1 auto"
						background="--color-pr0Orange"
					>
						Die Erstellung der Website kostet einmalig 459€.{" "}
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-pr0Orange"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					border-width="0 0 0 0"
					border-color="--color-secondary"
					lg-padding="48px 35px 56px 35px"
					position="relative"
					color="--proweiss"
					height="max-content"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						monatlich
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline1">
						49€
					</Text>
					<Text
						margin="0px 0px 32px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						background="--color-pr0Orange"
					>
						pro Monat
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						flex="1 1 auto"
						background="--color-pr0Orange"
					>
						Die laufenden Kosten für Hosting und support betragen 49€ monatlich. Dabei sind bis zu zwei Änderungen an Ihrer Website inklusive.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-pr0Orange"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
					color="--proweiss"
					height="max-content"
					hover-transition="all --transitionDuration-fast ease 10ms"
					hover-transform="scale(1.01)"
					hover-box-shadow="--m"
					transition="all --transitionDuration-fast ease 10ms"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						bei Bedarf
					</Text>
					<Text margin="0px 0px 8px 0px" font="--headline1">
						39€
					</Text>
					<Text
						margin="0px 0px 32px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						background="--color-pr0Orange"
					>
						pro extra Leistung
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--proweiss"
						text-align="center"
						font="--base"
						flex="1 1 auto"
						background="--color-pr0Orange"
					>
						Haben Sie Erweitere Änderungswünsche die über die monatlich inkludierten Leistungen steigen, berechnen wir Ihen pro Änderung 39€ zusätzlich.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"6349caa26459a595bf58107e"}>
				{"overflow: hidden;"}
			</style>
		</RawHtml>
	</Theme>;
});